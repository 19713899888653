import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  cardSelector = '.get-started-content-card'
  imagesParentSelector = '.get-started-content-images'
  visibleClass = 'visible'

  activeImageEl = null
  imageEls = []

  initialize() {
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.imageEls = document.querySelector(this.imagesParentSelector).querySelectorAll('img')
  }

  connect() {
    this.cardElements.forEach(el => el.addEventListener('mouseenter', this.onMouseEnter))

    this.activateImage(this.imageEls[0])
  }

  disconnect() {
    this.cardElements.forEach(el => el.removeEventListener('mouseenter', this.onMouseEnter))
  }

  onMouseEnter(event) {
    const imageEl = this.getTargetElFromCard(event)
    this.activateImage(imageEl)
  }

  activateImage(imageEl) {
    if (this.activeImageEl === imageEl) return

    this.imageEls.forEach(el => el.classList.remove(this.visibleClass))
    imageEl.classList.add(this.visibleClass)

    this.activeImageEl = imageEl
  }

  getTargetElFromCard(event) {
    const element = event.currentTarget
    const targetId = element.dataset.targetId
    return document.getElementById(targetId)
  }

  get cardElements() {
    return this.element.querySelectorAll(this.cardSelector)
  }
}
