import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['observe', 'change']

  imagesParent = null

  initialize() {
    this.imagesParent = this.changeTargets[0].parentElement
    this.scrollHandler = this.scrollHandler.bind(this)
    this.initializeChangeTargets()
    this.scrollHandler()
  }

  connect() {
    window.addEventListener('scroll', this.scrollHandler)
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  calculateOffset() {
    const screenHeight = window.innerHeight
    const stickyHeight = this.imagesParent.clientHeight
    const stickyOffset = (screenHeight - stickyHeight) / 2

    this.imagesParent.style.top = `${stickyOffset}px`
  }

  scrollHandler() {
    this.calculateOffset()

    this.observeTargets.forEach((target, index) => {
      const targetRect = target.getBoundingClientRect()
      const imageRect = this.imagesParent.getBoundingClientRect()
      const windowCenter = window.innerHeight / 2

      const previousTargetRect = this.observeTargets[index - 1]?.getBoundingClientRect()
      let additionalOffset = 0

      if (previousTargetRect) {
        const distanceBetweenTargets = targetRect.top - previousTargetRect.bottom
        additionalOffset = distanceBetweenTargets / 2
      }

      const imageOffset = windowCenter - (imageRect.bottom - imageRect.height / 2)

      const triggerPoint = targetRect.top + imageOffset - additionalOffset

      if (triggerPoint < windowCenter) {
        this.changeTargets.forEach(target => target.classList.remove('visible'))
        this.changeTargets[index].classList.add('visible')
      }
    })
  }

  initializeChangeTargets() {
    this.changeTargets.forEach(target => target.classList.remove('visible'))
    this.changeTargets[0].classList.add('visible')
  }
}
